import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DeleteReportIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 80, height: 80, fill: "white" }} viewBox="0 0 80 80" {...props}>
      <path
        d="M44.5 74.7H14.1499V5.3H65.8499V54"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25.8501 23.6H54.0501" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.8501 35H54.0501" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.8501 46.5H54.0501" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51 60L65.8 74.7" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M65.8 60L51 74.7" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}
