import { SvgIcon, SvgIconProps } from "@mui/material";

export const ReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 24, height: 20 }} viewBox="0 0 16 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1998 0.399902C2.56329 0.399902 1.95284 0.652759 1.50275 1.10285C1.05266 1.55293 0.799805 2.16338 0.799805 2.7999V17.1999C0.799805 17.8364 1.05266 18.4469 1.50275 18.897C1.95284 19.347 2.56329 19.5999 3.1998 19.5999H12.7998C13.4363 19.5999 14.0468 19.347 14.4969 18.897C14.9469 18.4469 15.1998 17.8364 15.1998 17.1999V6.8967C15.1997 6.26023 14.9467 5.64988 14.4966 5.1999L10.3998 1.1031C9.94982 0.652984 9.33947 0.400038 8.70301 0.399902H3.1998ZM5.5998 12.3999C5.5998 12.0816 5.47338 11.7764 5.24833 11.5514C5.02329 11.3263 4.71806 11.1999 4.3998 11.1999C4.08155 11.1999 3.77632 11.3263 3.55128 11.5514C3.32623 11.7764 3.1998 12.0816 3.1998 12.3999V15.9999C3.1998 16.3182 3.32623 16.6234 3.55128 16.8484C3.77632 17.0735 4.08155 17.1999 4.3998 17.1999C4.71806 17.1999 5.02329 17.0735 5.24833 16.8484C5.47338 16.6234 5.5998 16.3182 5.5998 15.9999V12.3999ZM7.9998 8.7999C8.31806 8.7999 8.62329 8.92633 8.84833 9.15137C9.07338 9.37642 9.19981 9.68164 9.19981 9.9999V15.9999C9.19981 16.3182 9.07338 16.6234 8.84833 16.8484C8.62329 17.0735 8.31806 17.1999 7.9998 17.1999C7.68155 17.1999 7.37632 17.0735 7.15128 16.8484C6.92623 16.6234 6.7998 16.3182 6.7998 15.9999V9.9999C6.7998 9.68164 6.92623 9.37642 7.15128 9.15137C7.37632 8.92633 7.68155 8.7999 7.9998 8.7999V8.7999ZM12.7998 7.5999C12.7998 7.28164 12.6734 6.97642 12.4483 6.75137C12.2233 6.52633 11.9181 6.3999 11.5998 6.3999C11.2815 6.3999 10.9763 6.52633 10.7513 6.75137C10.5262 6.97642 10.3998 7.28164 10.3998 7.5999V15.9999C10.3998 16.3182 10.5262 16.6234 10.7513 16.8484C10.9763 17.0735 11.2815 17.1999 11.5998 17.1999C11.9181 17.1999 12.2233 17.0735 12.4483 16.8484C12.6734 16.6234 12.7998 16.3182 12.7998 15.9999V7.5999Z"
      />
    </SvgIcon>
  );
};

export default ReportIcon;
