import { SvgIcon, SvgIconProps } from "@mui/material";

const IndustriesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 21 18" {...props}>
    <path
      d="M4.66667 1.5C3.74583 1.5 3 2.17125 3 3V9.75C3 10.164 3.37333 10.5 3.83333 10.5C4.29333 10.5 4.66667 10.164 4.66667 9.75V3.75C4.66667 3.336 5.04 3 5.5 3H11.3333C11.3333 2.17125 10.5875 1.5 9.66667 1.5H4.66667ZM8 4.5C7.07917 4.5 6.33333 5.17125 6.33333 6V12.75C6.33333 13.164 6.70667 13.5 7.16667 13.5C7.62667 13.5 8 13.164 8 12.75V6.75C8 6.336 8.37333 6 8.83333 6H14.6667C14.6667 5.17125 13.9208 4.5 13 4.5H8ZM11.3333 7.5C10.4125 7.5 9.66667 8.17125 9.66667 9V15.75C9.66667 16.164 10.04 16.5 10.5 16.5C10.96 16.5 11.3333 16.164 11.3333 15.75V15H16.3333V15.75C16.3333 16.164 16.7067 16.5 17.1667 16.5C17.6267 16.5 18 16.164 18 15.75V9C18 8.17125 17.2542 7.5 16.3333 7.5H11.3333ZM11.75 9H12.5833C12.8133 9 13 9.168 13 9.375V10.125C13 10.332 12.8133 10.5 12.5833 10.5H11.75C11.52 10.5 11.3333 10.332 11.3333 10.125V9.375C11.3333 9.168 11.52 9 11.75 9ZM15.0833 9H15.9167C16.1467 9 16.3333 9.168 16.3333 9.375V10.125C16.3333 10.332 16.1467 10.5 15.9167 10.5H15.0833C14.8533 10.5 14.6667 10.332 14.6667 10.125V9.375C14.6667 9.168 14.8533 9 15.0833 9ZM11.75 12H12.5833C12.8133 12 13 12.168 13 12.375V13.125C13 13.332 12.8133 13.5 12.5833 13.5H11.75C11.52 13.5 11.3333 13.332 11.3333 13.125V12.375C11.3333 12.168 11.52 12 11.75 12ZM15.0833 12H15.9167C16.1467 12 16.3333 12.168 16.3333 12.375V13.125C16.3333 13.332 16.1467 13.5 15.9167 13.5H15.0833C14.8533 13.5 14.6667 13.332 14.6667 13.125V12.375C14.6667 12.168 14.8533 12 15.0833 12Z"
      fill="#8E9BA9"
    />
  </SvgIcon>
);

export default IndustriesIcon;
