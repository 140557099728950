import { SvgIcon, SvgIconProps } from "@mui/material";

export const Folder = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M16.667 5.00065H10.0003L8.33366 3.33398H3.33366C2.41699 3.33398 1.67533 4.08398 1.67533 5.00065L1.66699 15.0006C1.66699 15.9173 2.41699 16.6673 3.33366 16.6673H16.667C17.5837 16.6673 18.3337 15.9173 18.3337 15.0006V6.66732C18.3337 5.75065 17.5837 5.00065 16.667 5.00065ZM16.667 15.0006H3.33366V6.66732H16.667V15.0006Z"
        fill="#505C6D"
      />
    </SvgIcon>
  );
};
export default Folder;
