import { SvgIconComponent } from "@mui/icons-material";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const EditDocumentIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  const color = props.color === "primary" ? theme.palette.primary.main : theme.palette.secondary.light;
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: "none" }} {...props}>
      <path
        d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V12H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8V4H6V20H12V22H6ZM18.3 14.525L19.375 15.6L15.5 19.45V20.5H16.55L20.425 16.65L21.475 17.7L17.475 21.7C17.375 21.8 17.2625 21.875 17.1375 21.925C17.0125 21.975 16.8833 22 16.75 22H14.5C14.3667 22 14.25 21.95 14.15 21.85C14.05 21.75 14 21.6333 14 21.5V19.25C14 19.1167 14.025 18.9875 14.075 18.8625C14.125 18.7375 14.2 18.625 14.3 18.525L18.3 14.525ZM21.475 17.7L18.3 14.525L19.75 13.075C19.9333 12.8917 20.1667 12.8 20.45 12.8C20.7333 12.8 20.9667 12.8917 21.15 13.075L22.925 14.85C23.1083 15.0333 23.2 15.2667 23.2 15.55C23.2 15.8333 23.1083 16.0667 22.925 16.25L21.475 17.7Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default EditDocumentIcon as SvgIconComponent;
