import { SvgIcon, SvgIconProps } from "@mui/material";

export const FolderOpen = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M3.33366 16.6673C2.87533 16.6673 2.4831 16.5043 2.15699 16.1781C1.83033 15.8515 1.66699 15.459 1.66699 15.0006V5.00065C1.66699 4.54232 1.83033 4.1501 2.15699 3.82398C2.4831 3.49732 2.87533 3.33398 3.33366 3.33398H8.33366L10.0003 5.00065H16.667C17.1253 5.00065 17.5178 5.16398 17.8445 5.49065C18.1706 5.81676 18.3337 6.20898 18.3337 6.66732H9.31283L7.64616 5.00065H3.33366V15.0006L5.33366 8.33398H19.5837L17.4378 15.4798C17.3267 15.8409 17.122 16.1293 16.8237 16.3448C16.5248 16.5598 16.1948 16.6673 15.8337 16.6673H3.33366ZM5.08366 15.0006H15.8337L17.3337 10.0007H6.58366L5.08366 15.0006Z"
        fill="#505C6D"
      />
    </SvgIcon>
  );
};
export default FolderOpen;
