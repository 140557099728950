import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteDocumentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 62 59" {...props}>
    <g id="Group">
      <g id="Group_2">
        <path
          id="Vector"
          d="M0 0V52.5H35.6C33 51.5 30.7 49.8 29.1 47.5H5V5H47.5V24.8C49.4 25.4 51.1 26.4 52.5 27.7V0H0Z"
          fill="#C7DBE5"
        />
      </g>
      <g id="Group_3">
        <path id="Vector_2" d="M39.5001 10.2998H13.1001V15.2998H39.5001V10.2998Z" fill="#C7DBE5" />
      </g>
      <path
        id="Vector_3"
        d="M43.3002 58.2996C33.0002 58.2996 24.7002 49.9996 24.7002 39.6996C24.7002 29.4996 33.0002 21.0996 43.3002 21.0996C53.5002 21.0996 61.9002 29.3996 61.9002 39.6996C61.9002 49.9996 53.5002 58.2996 43.3002 58.2996ZM43.3002 26.0996C35.8002 26.0996 29.7002 32.1996 29.7002 39.6996C29.7002 47.1996 35.8002 53.2996 43.3002 53.2996C50.8002 53.2996 56.9002 47.1996 56.9002 39.6996C56.9002 32.1996 50.8002 26.0996 43.3002 26.0996Z"
        fill="#C7DBE5"
      />
      <path
        id="Vector_4"
        d="M47.5002 21.5996C46.1002 21.2996 44.7002 21.0996 43.3002 21.0996C33.1002 21.0996 24.7002 29.3996 24.7002 39.6996C24.7002 42.4996 25.3002 45.0996 26.4002 47.4996C27.3002 49.2996 28.4002 50.9996 29.8002 52.4996H38.6002C36.0002 51.4996 33.7002 49.7996 32.1002 47.4996C30.5002 45.2996 29.7002 42.5996 29.7002 39.6996C29.7002 32.1996 35.8002 26.0996 43.3002 26.0996C44.8002 26.0996 46.2002 26.2996 47.5002 26.7996C49.4002 27.3996 51.1002 28.3996 52.5002 29.6996V23.4996C50.9002 22.6996 49.3002 21.9996 47.5002 21.5996Z"
        fill="#C7DBE5"
      />
      <g id="Group_4">
        <g id="Group_5">
          <path
            id="Vector_5"
            d="M46.8125 32.6314L36.1353 43.3086L39.6708 46.8441L50.348 36.1669L46.8125 32.6314Z"
            fill="#F44336"
          />
        </g>
        <g id="Group_6">
          <path
            id="Vector_6"
            d="M39.7416 32.6344L36.2061 36.1699L46.8833 46.8471L50.4188 43.3116L39.7416 32.6344Z"
            fill="#F44336"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DeleteDocumentIcon;
