import { SvgIcon, SvgIconProps } from "@mui/material";

export const PdfIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM9.5 7.5C9.5 8.33 8.83 9 8 9H7V11H5.5V5H8C8.83 5 9.5 5.67 9.5 6.5V7.5ZM14.5 9.5C14.5 10.33 13.83 11 13 11H10.5V5H13C13.83 5 14.5 5.67 14.5 6.5V9.5ZM18.5 6.5H17V7.5H18.5V9H17V11H15.5V5H18.5V6.5ZM7 7.5H8V6.5H7V7.5ZM2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM12 9.5H13V6.5H12V9.5Z"
        fill="#505C6D"
      />
    </SvgIcon>
  );
};
export default PdfIcon;
