import { ComponentType } from "react";
import CurrencyIcon from "../../bi/icons/CurrencyIcon";
import DataRefreshIcon from "../../bi/icons/DataRefreshIcon";
import DeleteMeasureIcon from "../../bi/icons/DeleteMeasureIcon";
import EqualSignIcon from "../../bi/icons/EqualSignIcon";
import ExcelIcon1 from "../../bi/icons/ExcelIcon";
import ExportImageIcon from "../../bi/icons/ExportImageIcon";
import ExportPdfIcon from "../../bi/icons/ExportPdfIcon";
import ExportSvgIcon from "../../bi/icons/ExportSvgIcon";
import Folder from "../../bi/icons/Folder";
import FolderOpen from "../../bi/icons/FolderOpen";
import LinkIcon from "../../bi/icons/LinkIcon";
import NumberIcon from "../../bi/icons/NumberIcon";
import PdfIcon1 from "../../bi/icons/PdfIcon";
import PercentageIcon from "../../bi/icons/PercentageIcon";
import ReportWarningIcon from "../../bi/icons/ReportWarningIcon";
import ReportingBackIcon from "../../bi/icons/ReportingBackIcon";
import ReportingIcon from "../../bi/icons/ReportingIcon";
import ReverseSignIcon from "../../bi/icons/ReverseSignIcon";
import SortAscIcon from "../../bi/icons/SortAscIcon";
import SortDescIcon from "../../bi/icons/SortDescIcon";
import SourceEnvironmentIcon from "../../bi/icons/SourceEnvironmentIcon";
import TileChartIcon from "../../bi/icons/TileChartIcon";
import WarningIcon from "../../bi/icons/WarningIcon";
import EntriliaLogo from "../../community/icons/EntriliaLogo";
import IndustriesIcon from "../../community/icons/IndustriesIcon";
import ApiGatewayIcon from "../../cp/icons/ApiGatewayIcon";
import CancelReportsCopyingIcon from "../../cp/icons/CancelReportsCopyingIcon";
import CardAccountDetailsIcon from "../../cp/icons/CardAccountDetailsIcon";
import CommentDeletedIcon from "../../cp/icons/CommentDeletedIcon";
import CompanyIcon from "../../cp/icons/CompanyIcon";
import DashedArrowIconLeft from "../../cp/icons/DashedArrowIconLeft";
import DashedArrowIconRight from "../../cp/icons/DashedArrowIconRight";
import DataBackupIcon from "../../cp/icons/DataBackupIcon";
import DataIcon from "../../cp/icons/DataIcon";
import DeleteDocumentIcon1 from "../../cp/icons/DeleteDocumentIcon";
import DeletePersonIcon from "../../cp/icons/DeletePersonIcon";
import DeleteTagIcon from "../../cp/icons/DeleteTagIcon";
import DocumentIcon1 from "../../cp/icons/DocumentIcon";
import DwhShareCreatedIcon from "../../cp/icons/DwhShareCreatedIcon";
import DwhShareDeletedIcon from "../../cp/icons/DwhShareDeletedIcon";
import DwhShareIcon from "../../cp/icons/DwhShareIcon";
import EditDocumentIcon from "../../cp/icons/EditDocumentIcon";
import EmailIcon from "../../cp/icons/EmailIcon";
import FilesIcon from "../../cp/icons/FilesIcon";
import FundAccountingIcon from "../../cp/icons/FundAccountingIcon";
import GridViewRoundedIcon from "../../cp/icons/GridViewRoundedIcon";
import InvestorPortalIcon from "../../cp/icons/InvestorPortalIcon";
import QueryStatsIcon from "../../cp/icons/QueryStatsIcon";
import ReportingIcon1 from "../../cp/icons/ReportingIcon";
import UserDeletedIcon from "../../cp/icons/UserDeletedIcon";
import VerifiedShieldIcon from "../../cp/icons/VerifiedShieldIcon";
import ViewsIcon from "../../cp/icons/ViewsIcon";
import CheckboxFieldTypeIcon from "../../cp/icons/fieldTypes/CheckboxFieldTypeIcon";
import DeleteDialogIcon from "../../cp/icons/fieldTypes/DeleteDialogIcon";
import MoneyFieldTypeIcon from "../../cp/icons/fieldTypes/MoneyFieldTypeIcon";
import MultiSelectFieldTypeIcon from "../../cp/icons/fieldTypes/MultiSelectFieldTypeIcon";
import PencilNotFilledIcon from "../../cp/icons/fieldTypes/PencilNotFilledIcon";
import SelectFieldTypeIcon from "../../cp/icons/fieldTypes/SelectFieldTypeIcon";
import AdjustmentsIcon from "../../shared/icons/AdjustmentsIcon";
import AnnualMeetingsNoticesIcon from "../../shared/icons/AnnualMeetingsNoticesIcon";
import AnnualQuarterlyReportsIcon from "../../shared/icons/AnnualQuarterlyReportsIcon";
import ArrowDropDownMediumRoundedIcon from "../../shared/icons/ArrowDropDownMediumRoundedIcon";
import ArrowRightMediumRoundedIcon from "../../shared/icons/ArrowRightMediumRoundedIcon";
import AuthenticationFailedIcon from "../../shared/icons/AuthenticationFailedIcon";
import CapCallNoticeIcon from "../../shared/icons/CapCallNoticeIcon";
import CapitalAccountStatementIcon from "../../shared/icons/CapitalAccountStatementIcon";
import CircleIcon from "../../shared/icons/CircleIcon";
import CollapseAllIcon from "../../shared/icons/CollapseAllIcon";
import CompaniesIcon from "../../shared/icons/CompaniesIcon";
import DataErrorIcon from "../../shared/icons/DataErrorIcon";
import DeleteDocumentIcon from "../../shared/icons/DeleteDocumentIcon";
import DeleteFileIcon from "../../shared/icons/DeleteFileIcon";
import DeleteReportIcon from "../../shared/icons/DeleteReportIcon";
import DistributionNoticeIcon from "../../shared/icons/DistributionNoticeIcon";
import DocumentIcon from "../../shared/icons/DocumentIcon";
import DocumentSuccess from "../../shared/icons/DocumentSuccess";
import DuplicateReportIcon from "../../shared/icons/DuplicateReportIcon";
import EditReportIcon from "../../shared/icons/EditReportIcon";
import ErrorIcon from "../../shared/icons/ErrorIcon";
import ExpandAllIcon from "../../shared/icons/ExpandAllIcon";
import FinancialStatementIcon from "../../shared/icons/FinancialStatementIcon";
import FolderManagedIcon from "../../shared/icons/FolderManagedIcon";
import GeneralIcon from "../../shared/icons/GeneralIcon";
import ImagePlaceholderIcon from "../../shared/icons/ImagePlaceholderIcon";
import LoggedOutIcon from "../../shared/icons/LoggedOutIcon";
import MonitorWithBusinessAppIcon from "../../shared/icons/MonitorWithBusinessAppIcon";
import QuarterlyCapitalStatementsIcon from "../../shared/icons/QuarterlyCapitalStatementsIcon";
import QuarterlyNewsIcon from "../../shared/icons/QuarterlyNewsIcon";
import RenameReportIcon from "../../shared/icons/RenameReportIcon";
import ReportIcon from "../../shared/icons/ReportIcon";
import TaxIcon from "../../shared/icons/TaxIcon";
import TaxInformationIcon from "../../shared/icons/TaxInformationIcon";
import DefaultFileIcon from "../../shared/icons/fileFormats/DefaultFileIcon";
import ExcelIcon from "../../shared/icons/fileFormats/ExcelIcon";
import ImageFileIcon from "../../shared/icons/fileFormats/ImageFileIcon";
import PdfIcon from "../../shared/icons/fileFormats/PdfIcon";
import PowerPointIcon from "../../shared/icons/fileFormats/PowerPointIcon";
import WordIcon from "../../shared/icons/fileFormats/WordIcon";
import FunctionIcon from "../../shared/reporting/icons/FunctionIcon";
import HashIcon from "../../shared/reporting/icons/HashIcon";
import IconDocument from "../../shared/reporting/icons/IconDocument";
import NavigationParentIcon from "../../shared/reporting/icons/NavigationParentIcon";
import SigmaIcon from "../../shared/reporting/icons/SigmaIcon";
import AreaChartIcon from "../../shared/reporting/icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../shared/reporting/icons/reportTypes/BarChartIcon";
import DoughnutChartIcon from "../../shared/reporting/icons/reportTypes/DoughnutChartIcon";
import LineChartIcon from "../../shared/reporting/icons/reportTypes/LineChartIcon";
import PieChartIcon from "../../shared/reporting/icons/reportTypes/PieChartIcon";
import PivotIcon from "../../shared/reporting/icons/reportTypes/PivotIcon";
import TabularIcon from "../../shared/reporting/icons/reportTypes/TabularIcon";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Icons = (): { Icon: ComponentType<any>; name: string; group: string }[] => {
  return [
    { Icon: AdjustmentsIcon, name: "AdjustmentsIcon", group: "shared" },
    { Icon: AnnualMeetingsNoticesIcon, name: "AnnualMeetingsNoticesIcon", group: "shared" },
    { Icon: AnnualQuarterlyReportsIcon, name: "AnnualQuarterlyReportsIcon", group: "shared" },
    { Icon: ArrowDropDownMediumRoundedIcon, name: "ArrowDropDownMediumRoundedIcon", group: "shared" },
    { Icon: ArrowRightMediumRoundedIcon, name: "ArrowRightMediumRoundedIcon", group: "shared" },
    { Icon: AuthenticationFailedIcon, name: "AuthenticationFailedIcon", group: "shared" },
    { Icon: CapCallNoticeIcon, name: "CapCallNoticeIcon", group: "shared" },
    { Icon: CapitalAccountStatementIcon, name: "CapitalAccountStatementIcon", group: "shared" },
    { Icon: CircleIcon, name: "CircleIcon", group: "shared" },
    { Icon: CollapseAllIcon, name: "CollapseAllIcon", group: "shared" },
    { Icon: CompaniesIcon, name: "CompaniesIcon", group: "shared" },
    { Icon: DataErrorIcon, name: "DataErrorIcon", group: "shared" },
    { Icon: DeleteDocumentIcon, name: "DeleteDocumentIcon", group: "shared" },
    { Icon: DeleteReportIcon, name: "DeleteReportIcon", group: "shared" },
    { Icon: DistributionNoticeIcon, name: "DistributionNoticeIcon", group: "shared" },
    { Icon: DocumentIcon, name: "DocumentIcon", group: "shared" },
    { Icon: DocumentSuccess, name: "DocumentSuccess", group: "shared" },
    { Icon: DuplicateReportIcon, name: "DuplicateReportIcon", group: "shared" },
    { Icon: EditReportIcon, name: "EditReportIcon", group: "shared" },
    { Icon: ErrorIcon, name: "ErrorIcon", group: "shared" },
    { Icon: ExpandAllIcon, name: "ExpandAllIcon", group: "shared" },
    { Icon: FinancialStatementIcon, name: "FinancialStatementIcon", group: "shared" },
    { Icon: FolderManagedIcon, name: "FolderManagedIcon", group: "shared" },
    { Icon: GeneralIcon, name: "GeneralIcon", group: "shared" },
    { Icon: ImagePlaceholderIcon, name: "ImagePlaceholderIcon", group: "shared" },
    { Icon: LoggedOutIcon, name: "LoggedOutIcon", group: "shared" },
    { Icon: MonitorWithBusinessAppIcon, name: "MonitorWithBusinessAppIcon", group: "shared" },
    { Icon: QuarterlyCapitalStatementsIcon, name: "QuarterlyCapitalStatementsIcon", group: "shared" },
    { Icon: QuarterlyNewsIcon, name: "QuarterlyNewsIcon", group: "shared" },
    { Icon: RenameReportIcon, name: "RenameReportIcon", group: "shared" },
    { Icon: ReportIcon, name: "ReportIcon", group: "shared" },
    { Icon: TaxIcon, name: "TaxIcon", group: "shared" },
    { Icon: TaxInformationIcon, name: "TaxInformationIcon", group: "shared" },
    { Icon: DefaultFileIcon, name: "DefaultFileIcon", group: "shared" },
    { Icon: ExcelIcon, name: "ExcelIcon", group: "shared" },
    { Icon: ImageFileIcon, name: "ImageFileIcon", group: "shared" },
    { Icon: PdfIcon, name: "PdfIcon", group: "shared" },
    { Icon: PowerPointIcon, name: "PowerPointIcon", group: "shared" },
    { Icon: WordIcon, name: "WordIcon", group: "shared" },
    { Icon: FunctionIcon, name: "FunctionIcon", group: "shared/reporting" },
    { Icon: HashIcon, name: "HashIcon", group: "shared/reporting" },
    { Icon: IconDocument, name: "IconDocument", group: "shared/reporting" },
    { Icon: NavigationParentIcon, name: "NavigationParentIcon", group: "shared/reporting" },
    { Icon: SigmaIcon, name: "SigmaIcon", group: "shared/reporting" },
    { Icon: AreaChartIcon, name: "AreaChartIcon", group: "shared/reporting" },
    { Icon: BarChartIcon, name: "BarChartIcon", group: "shared/reporting" },
    { Icon: DoughnutChartIcon, name: "DoughnutChartIcon", group: "shared/reporting" },
    { Icon: LineChartIcon, name: "LineChartIcon", group: "shared/reporting" },
    { Icon: PieChartIcon, name: "PieChartIcon", group: "shared/reporting" },
    { Icon: PivotIcon, name: "PivotIcon", group: "shared/reporting" },
    { Icon: TabularIcon, name: "TabularIcon", group: "shared/reporting" },
    { Icon: CurrencyIcon, name: "CurrencyIcon", group: "bi" },
    { Icon: DataRefreshIcon, name: "DataRefreshIcon", group: "bi" },
    { Icon: DeleteMeasureIcon, name: "DeleteMeasureIcon", group: "bi" },
    { Icon: EqualSignIcon, name: "EqualSignIcon", group: "bi" },
    { Icon: ExcelIcon1, name: "ExcelIcon", group: "bi" },
    { Icon: ExportImageIcon, name: "ExportImageIcon", group: "bi" },
    { Icon: ExportPdfIcon, name: "ExportPdfIcon", group: "bi" },
    { Icon: ExportSvgIcon, name: "ExportSvgIcon", group: "bi" },
    { Icon: Folder, name: "Folder", group: "bi" },
    { Icon: FolderOpen, name: "FolderOpen", group: "bi" },
    { Icon: LinkIcon, name: "LinkIcon", group: "bi" },
    { Icon: NumberIcon, name: "NumberIcon", group: "bi" },
    { Icon: PdfIcon1, name: "PdfIcon", group: "bi" },
    { Icon: PercentageIcon, name: "PercentageIcon", group: "bi" },
    { Icon: ReportWarningIcon, name: "ReportWarningIcon", group: "bi" },
    { Icon: ReportingBackIcon, name: "ReportingBackIcon", group: "bi" },
    { Icon: ReportingIcon, name: "ReportingIcon", group: "bi" },
    { Icon: ReverseSignIcon, name: "ReverseSignIcon", group: "bi" },
    { Icon: SortAscIcon, name: "SortAscIcon", group: "bi" },
    { Icon: SortDescIcon, name: "SortDescIcon", group: "bi" },
    { Icon: SourceEnvironmentIcon, name: "SourceEnvironmentIcon", group: "bi" },
    { Icon: TileChartIcon, name: "TileChartIcon", group: "bi" },
    { Icon: WarningIcon, name: "WarningIcon", group: "bi" },
    { Icon: EntriliaLogo, name: "EntriliaLogo", group: "community" },
    { Icon: IndustriesIcon, name: "IndustriesIcon", group: "community" },
    { Icon: ApiGatewayIcon, name: "ApiGatewayIcon", group: "cp" },
    { Icon: CancelReportsCopyingIcon, name: "CancelReportsCopyingIcon", group: "cp" },
    { Icon: CardAccountDetailsIcon, name: "CardAccountDetailsIcon", group: "cp" },
    { Icon: CommentDeletedIcon, name: "CommentDeletedIcon", group: "cp" },
    { Icon: CompanyIcon, name: "CompanyIcon", group: "cp" },
    { Icon: DashedArrowIconLeft, name: "DashedArrowIconLeft", group: "cp" },
    { Icon: DashedArrowIconRight, name: "DashedArrowIconRight", group: "cp" },
    { Icon: DataBackupIcon, name: "DataBackupIcon", group: "cp" },
    { Icon: DataIcon, name: "DataIcon", group: "cp" },
    { Icon: DeleteDocumentIcon1, name: "DeleteDocumentIcon", group: "cp" },
    { Icon: DeleteFileIcon, name: "DeleteFileIcon", group: "cp" },
    { Icon: DeletePersonIcon, name: "DeletePersonIcon", group: "cp" },
    { Icon: DeleteTagIcon, name: "DeleteTagIcon", group: "cp" },
    { Icon: DocumentIcon1, name: "DocumentIcon", group: "cp" },
    { Icon: DwhShareCreatedIcon, name: "DwhShareCreatedIcon", group: "cp" },
    { Icon: DwhShareDeletedIcon, name: "DwhShareDeletedIcon", group: "cp" },
    { Icon: DwhShareIcon, name: "DwhShareIcon", group: "cp" },
    { Icon: EditDocumentIcon, name: "EditDocumentIcon", group: "cp" },
    { Icon: EmailIcon, name: "EmailIcon", group: "cp" },
    { Icon: FilesIcon, name: "FilesIcon", group: "cp" },
    { Icon: FundAccountingIcon, name: "FundAccountingIcon", group: "cp" },
    { Icon: GridViewRoundedIcon, name: "GridViewRoundedIcon", group: "cp" },
    { Icon: InvestorPortalIcon, name: "InvestorPortalIcon", group: "cp" },
    { Icon: QueryStatsIcon, name: "QueryStatsIcon", group: "cp" },
    { Icon: ReportingIcon1, name: "ReportingIcon", group: "cp" },
    { Icon: UserDeletedIcon, name: "UserDeletedIcon", group: "cp" },
    { Icon: VerifiedShieldIcon, name: "VerifiedShieldIcon", group: "cp" },
    { Icon: ViewsIcon, name: "ViewsIcon", group: "cp" },
    { Icon: CheckboxFieldTypeIcon, name: "CheckboxFieldTypeIcon", group: "cp" },
    { Icon: DeleteDialogIcon, name: "DeleteDialogIcon", group: "cp" },
    { Icon: MoneyFieldTypeIcon, name: "MoneyFieldTypeIcon", group: "cp" },
    { Icon: MultiSelectFieldTypeIcon, name: "MultiSelectFieldTypeIcon", group: "cp" },
    { Icon: PencilNotFilledIcon, name: "PencilNotFilledIcon", group: "cp" },
    { Icon: SelectFieldTypeIcon, name: "SelectFieldTypeIcon", group: "cp" },
  ];
};

export default Icons;
