import { Grid, Typography, createTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { Fragment } from "react";
import ScrollableFlexContainer from "../../shared/components/ScrollableFlexContainer";
import { lightThemeOptions } from "../../shared/theme";

const TypographyPage = () => {
  const theme = createTheme(lightThemeOptions);

  return (
    <ScrollableFlexContainer>
      <Grid container sx={{ p: 3, gap: 2, display: "grid", gridTemplateColumns: "100px 200px 0.5fr" }}>
        {Object.keys(theme.typography).map((key: string, index: number) => {
          const value = theme.typography[key as unknown as Variant];
          if (typeof value !== "object") {
            return null;
          }
          return (
            <Fragment key={key + index}>
              <Typography variant="h5" color={"secondary"}>
                {key}
              </Typography>
              <Typography variant={key as Variant}>Typography</Typography>
              <Grid sx={{ display: "grid", gridTemplateColumns: "100px 50px" }}>
                {Object.entries(value).map(([key, value], index) => {
                  return (
                    <Fragment key={key + index}>
                      <Typography color={"secondary"}>{key}:</Typography>
                      <Typography>{value as string}</Typography>
                    </Fragment>
                  );
                })}
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </ScrollableFlexContainer>
  );
};

export default TypographyPage;
