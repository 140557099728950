import { SvgIcon, SvgIconProps } from "@mui/material";

export const TaxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="-3 0 24 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0001 0.399902C2.36358 0.399902 1.75313 0.652759 1.30304 1.10285C0.852954 1.55293 0.600098 2.16338 0.600098 2.7999V19.5999L4.8001 17.1999L9.0001 19.5999L13.2001 17.1999L17.4001 19.5999V2.7999C17.4001 2.16338 17.1472 1.55293 16.6972 1.10285C16.2471 0.652759 15.6366 0.399902 15.0001 0.399902H3.0001ZM6.0001 3.9999C5.52271 3.9999 5.06487 4.18954 4.72731 4.52711C4.38974 4.86468 4.2001 5.32251 4.2001 5.7999C4.2001 6.27729 4.38974 6.73513 4.72731 7.07269C5.06487 7.41026 5.52271 7.5999 6.0001 7.5999C6.47749 7.5999 6.93532 7.41026 7.27289 7.07269C7.61046 6.73513 7.8001 6.27729 7.8001 5.7999C7.8001 5.32251 7.61046 4.86468 7.27289 4.52711C6.93532 4.18954 6.47749 3.9999 6.0001 3.9999V3.9999ZM13.4485 4.3515C13.2235 4.12654 12.9183 4.00016 12.6001 4.00016C12.2819 4.00016 11.9767 4.12654 11.7517 4.3515L4.5517 11.5515C4.43709 11.6622 4.34567 11.7946 4.28278 11.941C4.21989 12.0874 4.18678 12.2449 4.1854 12.4042C4.18401 12.5636 4.21437 12.7216 4.27471 12.869C4.33505 13.0165 4.42415 13.1505 4.53682 13.2632C4.64949 13.3758 4.78348 13.465 4.93095 13.5253C5.07843 13.5856 5.23644 13.616 5.39578 13.6146C5.55511 13.6132 5.71258 13.5801 5.85898 13.5172C6.00539 13.4543 6.1378 13.3629 6.2485 13.2483L13.4485 6.0483C13.6735 5.82327 13.7998 5.5181 13.7998 5.1999C13.7998 4.88171 13.6735 4.57654 13.4485 4.3515V4.3515ZM12.0001 9.9999C11.5227 9.9999 11.0649 10.1895 10.7273 10.5271C10.3897 10.8647 10.2001 11.3225 10.2001 11.7999C10.2001 12.2773 10.3897 12.7351 10.7273 13.0727C11.0649 13.4103 11.5227 13.5999 12.0001 13.5999C12.4775 13.5999 12.9353 13.4103 13.2729 13.0727C13.6105 12.7351 13.8001 12.2773 13.8001 11.7999C13.8001 11.3225 13.6105 10.8647 13.2729 10.5271C12.9353 10.1895 12.4775 9.9999 12.0001 9.9999V9.9999Z"
      />
    </SvgIcon>
  );
};

export default TaxIcon;
