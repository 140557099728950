export const Pages = {
  main: {
    title: "Main",
    pageName: "/",
  },
  typography: {
    title: "Typography",
    pageName: "typography",
  },
  icons: {
    title: "Icons",
    pageName: "icons",
  },
};
